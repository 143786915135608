import React from 'react'
import loadable from '@loadable/component'
import HeaderLogo from './../../images/ZeroDT_Logo_OK_Black.svg'
import ExperienceDesign from './../../images/icons/experience_design.svg'
import EngineeringTeam from './../../images/icons/engineering_team.svg'
import './header.sass'

const Navigation = loadable(() => import('../navigation'))

const Header = () => {
  const scrollToContactUs = () => {
    const contactUs = document.getElementsByClassName('contact-us-form')
    contactUs[0].scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="header-wrapper">
            <img className="header-logo" alt="Logo" src={HeaderLogo} />
            <Navigation />
          </div>
          <div className="header-section">
            <div className="header-image-bg appear-anim">
              <div className="header-infobox">
                <h1>IT Staffing and Process Improvement</h1>
                <p>
                  We offer tailor-made technology staffing solutions to meet performance
                  expectations.
                </p>
              </div>
              <img
                className="header-image"
                height="200"
                alt="Experience Design"
                src={ExperienceDesign}
              />
            </div>
            <div className="header-image-bg appear-anim">
              <img
                className="header-image"
                height="200"
                alt="Engineering Team"
                src={EngineeringTeam}
              />
              <div className="header-infobox">
                <h1>Business Development and Consultancy</h1>
                <p>We invest in working business models and offer profound consulting expertise.</p>
              </div>
            </div>
            <button className="contact-us-btn fade-in-anim" onClick={scrollToContactUs}>
              Contact Us
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header

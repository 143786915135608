import * as React from 'react'
import loadable from '@loadable/component'
import Header from '../components/header'
import SearchEngineOptimization from '../components/seo'
import '../styles/variables.sass'
import './homepage.sass'
import '../styles/fonts.sass'

const Footer = loadable(() => import('../components/footer'))
const CookieConsent = loadable(() => import('../components/cookies'))
const Clients = loadable(() => import('../components/clients'))
const CoreExpertise = loadable(() => import('../components/core-expertise'))
const Stats = loadable(() => import('../components/stats'))
const ContactUs = loadable(() => import('../components/contact-us'))

const HomePage = () => {
  return (
    <>
      <SearchEngineOptimization />
      <Header />
      <Clients />
      <CoreExpertise />
      <Stats />
      <ContactUs />
      <CookieConsent />
      <Footer />
    </>
  )
}

export default HomePage

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

const SearchEngineOptimization = ({ title, description, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const { defaultTitle, titleTemplate, defaultDescription, siteUrl } = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`
  }
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang="en" />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      <meta
        name="keywords"
        content="gaming, casino, sportsbook, zerodt, crypto, cms, solutions, software, consultancy, technology, sports, betting, market, company, leader, industry, customers, management, online, website, cryptocurrencies, operator, specialists, platform, payment, engine, kyc, bonus, zero, dt"
      ></meta>
    </Helmet>
  )
}

export default SearchEngineOptimization

SearchEngineOptimization.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool
}
SearchEngineOptimization.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`
